import { useSetupContext } from "context/setupContext"
import { useUserContext } from "context/userContext"
import { Link } from "gatsby"
import { useNavBar } from "hooks/useNavBar"
import Logo from "images/logo/logo-red.svg"
import GridLayout from "layouts/gridLayout"
import PropTypes from "prop-types"
import React from "react"

/**
 * A layout with no navbar or footer and a small logo in the top-left corner
 *
 * Whitelist classes for PurgeCSS
 * (See: https://www.viget.com/articles/a-better-approach-for-using-purgecss-with-tailwind/)
 *
 * col-span-1 col-span-2 col-span-3 col-span-4  col-span-5  col-span-6
 * col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12
 */
const CleanLayout = (props) => {
  const {
    topRightSlot,
    topPadding = true,
    childColStart = 1,
    childColSpan = "full",
    children,
  } = props

  // Get auth state from the context
  const { userId } = useUserContext()

  // Use the setup context to determine if we click away from this layout using the logo
  const { isLoaded, hasProfile, hasDevice } = useSetupContext()

  useNavBar({
    hasNavBar: false,
  })

  return (
    <GridLayout type="fixed">
      <div
        className="
          col-start-1 col-span-full
          mt-6 tablet:mt-10 laptop:mt-8 desktop:mt-10
          flex
          justify-between"
      >
        {/* Logo */}
        <Link
          to="/"
          onClickCapture={(e) => {
            // Don't let the user click the logo to go back to the home page
            // if this is a setup process flow!
            if (
              isLoaded &&
              userId !== undefined &&
              (hasProfile === false || hasDevice === false)
            ) {
              e.preventDefault()
            }
          }}
        >
          <Logo
            className="
              w-24 tablet:w-32 desktop:w-40
              h-12 tablet:h-16 desktop:h-20"
          />
        </Link>

        {/* Spacer */}
        <div />

        {/* Top-right slot */}
        <div className="mt-1.5 tablet:mt-3 desktop:mt-3.5">{topRightSlot}</div>
      </div>

      {/* Child component */}
      <div
        className={`
          col-start-${childColStart}
          col-span-${childColSpan}
          pb-10 tablet:pb-0
          ${
            topPadding === true
              ? "mt-8 tablet:mt-28 laptop:mt-26 desktop:mt-32"
              : ""
          }
        `}
      >
        {/* Layout children */}
        {children}
      </div>
    </GridLayout>
  )
}

CleanLayout.propTypes = {
  topRightSlot: PropTypes.node,
  topPadding: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default CleanLayout
