import { graphql, useStaticQuery } from "gatsby"
import htmlToText from "html-to-text"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import Helmet from "react-helmet"

/**
 *
 * Metadata component that queries for data with Gatsby's useStaticQuery React hook.
 *
 * This component is also responsible for generating the OpenGraph metadata, for social linking.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
function Metadata({
  title,
  description,
  image,
  pathname,
  useStrapLine,
  lang,
  meta,
}) {
  //
  // Get metadata from gatsby-config.js
  //
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            strapLine
            description
            image
            keywords
            siteUrl
          }
        }
      }
    `
  )

  // Path to dynamic image
  const imagePath = `https://superfonik.imgix.net/${image}?fit=fill&fill=blur&w=1200&h=630`

  const siteUrl = site.siteMetadata.siteUrl
  const metaDescription = description
    ? htmlToText.convert(description, {
        wordwrap: false,
      })
    : site.siteMetadata.description
  const metaImage = image || site.siteMetadata.image
  const metaKeywords = site.siteMetadata.keywords

  // Determine the metadata title
  const metaTitle = useMemo(() => {
    if (title) {
      if (useStrapLine) {
        return `${title} | ${site.siteMetadata.title} | ${site.siteMetadata.strapLine}`
      } else {
        return `${title} | ${site.siteMetadata.title}`
      }
    } else {
      return site.siteMetadata.title
    }
  }, [
    title,
    useStrapLine,
    site.siteMetadata.strapLine,
    site.siteMetadata.title,
  ])

  // Determine the title template
  const titleTemplate = useMemo(() => {
    if (useStrapLine) {
      return `%s | ${site.siteMetadata.title} | ${site.siteMetadata.strapLine}`
    } else {
      return `%s | ${site.siteMetadata.title}`
    }
  }, [useStrapLine, site.siteMetadata.strapLine, site.siteMetadata.title])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: metaKeywords,
        },
        //
        // Open Graph for social previews
        //
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        //
        // Combined with meta description field for efficiency!
        //
        // {
        //   property: `og:description`,
        // },
        {
          property: `og:image`,
          content: image ? imagePath : `${siteUrl}${metaImage}`,
        },
        {
          property: `og:image:alt`,
          content: image ? `Cover art for ${metaTitle}` : "Superfonik Logo",
        },
        {
          property: `og:image:width`,
          content: "1200",
        },
        {
          property: `og:image:height`,
          content: "630",
        },
        {
          property: `og:url`,
          content: `${siteUrl}${pathname}`,
        },

        //
        // Twitter
        //
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Metadata.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  useStrapLine: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default Metadata
